import { Panel, View } from "@vkontakte/vkui"
import { useState } from "react"
import EventsDetails from "../Calendar/Events/EventDetails"
import NewsDetails from "../News/NewsDetails"
import Gospublics from "./Gospublics"
import MainView from "./MainView"
import SpaceDetails from "./Spaces/SpaceDetails"
import SpaceList from "./Spaces/SpaceList"
import WeRussiansDetails from "./WeRussians/WeRussiansDetails"
import WeRussians from "./WeRussians/WeRussiansList"
import EventsList from "./Events/EventsList"
import EventsFilter from "./Events/EventsFilter"
import SpacesMap from "./SpacesMap";

interface IMainPanelProps {
  id: string
}

export type TMainPanel = 'main' | 'newsDetails' | 'eventsList' | 'eventDetails' | 'spaces' | 'spaceDetails' | 'gospublics' | 'weRussians' | 'weRussiansDetails' | 'filter' | 'spacesMap'

const MainPanel = ({ id }: IMainPanelProps) => {
  const [activePanel, setActivePanel] = useState<string>('main')
  const [newsDetailsId, setNewsDetailsId] = useState<string>('')
  const [spaceDetailsId, setSpaceDetailsId] = useState<string>('')
  const [eventDetailsId, setEventDetailsId] = useState<string>('')
  const [weRussiansDetailsId, setWeRussiansDetailsId] = useState<string>('')

  const goToPanel = (value: TMainPanel, id?: string) => {
    switch(value) {
      case 'newsDetails':
        setNewsDetailsId(id ? id : '')
        setActivePanel(value)
        break;
      case 'eventDetails':
        setEventDetailsId(id ? id : '')
        setActivePanel(value)
        break;
      case 'weRussiansDetails':
        setWeRussiansDetailsId(id ? id : '')
        setActivePanel(value)
        break;
      case 'spaceDetails':
        setSpaceDetailsId(id ? id : '')
        setActivePanel(value)
        break;
      default:
        setActivePanel(value)
    }
  }

  return (
    <Panel id={id}>
      <View activePanel={activePanel}>
        <MainView id="main" goToPanel={goToPanel} />
        <NewsDetails id="newsDetails" newsId={newsDetailsId} goBack={() => setActivePanel('main')} />
        <EventsList id="eventsList" goBack={setActivePanel} goToPanel={goToPanel} />
        <EventsDetails id="eventDetails" goBack={() => setActivePanel('eventsList')} eventId={eventDetailsId} />
        <WeRussians id='weRussians' goToPanel={goToPanel} goBack={setActivePanel} />
        <WeRussiansDetails id='weRussiansDetails' goBack={() => setActivePanel('weRussians')} weRussiansId={weRussiansDetailsId} />
        <SpaceList id="spaces" goToPanel={goToPanel} goBack={setActivePanel} />
        <SpaceDetails id="spaceDetails" spaceId={spaceDetailsId} goBack={setActivePanel} />
        <Gospublics id="gospublics" goBack={setActivePanel} />
        <EventsFilter id="filter" goBack={setActivePanel} />
        <SpacesMap id="spacesMap" goBack={setActivePanel} />
      </View>
    </Panel>
  )
}

export default MainPanel
