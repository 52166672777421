import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { Icon, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import { boundingExtent } from "ol/extent";
import {ModalRoot, ModalCard, Button, Div, SimpleCell, Link, ModalPage} from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";
import {IMarker} from "../store/slices/markersSlice";
import {Icon16ChainOutline, Icon24ExternalLinkOutline, Icon16ClockOutline, Icon20TargetOutline} from "@vkontakte/icons";

type MapComponentProps = {
  markers: IMarker[];
};

const MapComponent: React.FC<MapComponentProps> = ({ markers }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [modalContent, setModalContent] = useState<{
    name: string;
    description: string;
    full_description: string;
    full_name: string;
    functional_spaces: string;
    opportunities: string;
    resident_organizations: string;
    vk_group_link: string;
    address: string;
    yandex_maps_mark: number;
    coordinates: [number, number]
  } | null>(null);

  useEffect(() => {
    if (!mapRef.current) return;

    // Инициализация карты
    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([20.130186, 54.67472]), // Координаты Москвы по умолчанию
        zoom: 10,
      }),
    });

    setMap(initialMap);

    return () => {
      initialMap.setTarget(undefined);
    };
  }, []);

  useEffect(() => {

    console.log(markers)

    if (!map || markers.length === 0) return;

    const features = markers.map((marker) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat(marker.coordinates)),
        cords: marker.coordinates,

        id: marker.id,
        name: marker.name,
        full_name: marker.full_name,
        full_description: marker.full_description,
        functional_spaces: marker.functional_spaces,
        opportunities: marker.opportunities,
        resident_organizations: marker.resident_organizations,
        vk_group_link: marker.vk_group_link,
        address: marker.address,
        description: marker.description,
        yandex_maps_mark: marker.yandex_maps_mark,
      });

      feature.setStyle(
          new Style({
            image: new Icon({
              src: "https://app.molodeg39.ru/storage/images/map-icon.png", // Замените на ваш URL иконки
              scale: 0.1,
            }),
          })
      );

      return feature;
    });

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    map.addLayer(vectorLayer);

    // Рассчитываем центр и масштаб карты
    const coordinates = markers.map((marker) => fromLonLat(marker.coordinates));
    const extent = boundingExtent(coordinates);
    const view = map.getView();
    view.fit(extent, { padding: [50, 50, 50, 50] });

    map.on("singleclick", (event) => {
      const feature = map.forEachFeatureAtPixel(event.pixel, (feat) => feat as Feature);
      if (feature) {
        const id = feature.get('id') as number;
        const name = feature.get("name") as string;
        const description = feature.get("description") as string;
        const full_name = feature.get('full_name') as string;
        const full_description = feature.get('full_description') as string;
        const functional_spaces = feature.get('functional_spaces') as string;
        const opportunities = feature.get('opportunities') as string;
        const resident_organizations = feature.get('resident_organizations') as string;
        const vk_group_link = feature.get('vk_group_link') as string;
        const address = feature.get('address') as string;
        const yandex_maps_mark = feature.get('yandex_maps_mark') as number;

        const geometry = feature.getGeometry();
        const cords = feature.get("cords");

        if (geometry instanceof Point) {
          const coordinates = geometry.getCoordinates();
          setModalContent({
            name,
            description,
            full_name,
            full_description,
            functional_spaces,
            opportunities,
            resident_organizations,
            vk_group_link,
            address,
            yandex_maps_mark,
            coordinates: [cords[0], cords[1]]
          });
          setActiveModal("markerModal");
        }
      }
    });
  }, [map, markers]);

  const openNavigator = () => {
    if (modalContent) {
      const [lon, lat] = modalContent.coordinates;
      const url = `https://yandex.ru/maps/?rtext=~${lat},${lon}`;
      window.open(url, "_blank");
    }
  };

  interface TextWithLineBreaksProps {
    text: string;
  }

  const TextWithLineBreaks: React.FC<TextWithLineBreaksProps> = ({ text }) => {
    // Разбиваем текст на массив строк по символу новой строки
    const lines = text.split('\n');

    return (
        <div>
          {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
          ))}
        </div>
    );
  };

  interface LinkWithLineBreaksProps {
    text: string;
  }

  const LinkWithLineBreaks: React.FC<LinkWithLineBreaksProps> = ({ text }) => {
    // Разбиваем текст на массив строк по символу двойного нового строки
    const paragraphs = text.split('\n\n');

    return (
        <div>
          {paragraphs.map((paragraph, index) => (
              <React.Fragment key={index}>
                <Link href={paragraph} target="_blank" rel="noopener noreferrer">
                  {paragraph}
                </Link>
                <br />
              </React.Fragment>
          ))}
        </div>
    );
  };

  interface NavigateButtonProps {
    lat: number;
    lng: number;
  }

  const NavigateButton: React.FC<NavigateButtonProps> = ({ lat, lng }) => {
    const handleNavigate = () => {
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        // Для устройств Apple
        window.open(`maps://maps.apple.com/?daddr=${lat},${lng}`, '_blank');
      } else {
        // Для устройств Android и других
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
      }
    };

    return (
        <Button size="l" onClick={handleNavigate}>
          Проложить маршрут
        </Button>
    );
  };

  return (
      <div>
        <div
            ref={mapRef}
            style={{ width: "100%", height: "calc(100vh - 97px)" }}
        ></div>

        <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
          <ModalPage
              id="markerModal"
              onClose={() => setActiveModal(null)}
              header={<Div style={{fontWeight: 'bold'}}>{modalContent?.name}</Div>}
              settlingHeight={100}
          >
            <Div style={{width: '100%', maxHeight: '75vh', overflowY: 'scroll'}}>
              <div style={{ width: '100%', paddingBottom: '50px'}}>
                <Div>{modalContent?.full_name}</Div>
                <SimpleCell before={<Icon16ClockOutline/>}>{modalContent?.description}</SimpleCell>
                <SimpleCell before={<Icon20TargetOutline/>}>{modalContent?.address}</SimpleCell>
                <div>
                  <Div className={"marker-modal_label"}>Функциональные пространства:</Div>
                  <Div className={"marker-modal_text"}>{<TextWithLineBreaks
                      text={modalContent?.functional_spaces as string}/>}</Div>
                </div>
                <div>
                  <Div className={"marker-modal_label"}>Возможности для молодежи:</Div>
                  <Div className={"marker-modal_text"}>{<TextWithLineBreaks
                      text={modalContent?.opportunities as string}/>}</Div>
                </div>
                <div>
                  <Div className={"marker-modal_label"}>Молодёжные организации:</Div>
                  <Div className={"marker-modal_text"}>{<TextWithLineBreaks
                      text={modalContent?.resident_organizations as string}/>}</Div>
                </div>

                <div>
                  <Div style={{fontSize: '11px'}}>
                    {modalContent?.full_description}
                  </Div>
                </div>

                <div>
                  <Div>
                    <LinkWithLineBreaks text={modalContent?.vk_group_link as string}/>
                  </Div>
                </div>

                <br/>
                <NavigateButton lat={modalContent?.coordinates[1] as number}
                                lng={modalContent?.coordinates[0] as number}/>
              </div>
            </Div>
          </ModalPage>
        </ModalRoot>
      </div>
  );
};

export default MapComponent;

// Пример использования
// const markers: Marker[] = [
//   { name: "Точка 1", description: "Описание точки 1", coordinates: [37.6173, 55.7558] },
//   { name: "Точка 2", description: "Описание точки 2", coordinates: [37.6200, 55.7600] },
// ];
// <MapComponent markers={markers} />;
