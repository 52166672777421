import { Icon28BookmarkOutline } from "@vkontakte/icons"
import FeedbackIcon from "../assets/icons/FeedbackIcon"
import GospublicsIcon from "../assets/icons/GospublicsIcon"
import ReservationIcon from "../assets/icons/ReservationIcon"
import TutorialIcon from "../assets/icons/TutorialIcon"
import WeRussiansIcon from "../assets/icons/WeRussiansIcon"
import MapIcon from "../assets/icons/MapIcon";
import { TMainPanel } from "../panels/Main/MainPanel"

interface IAdditionalSections {
  isModal?: boolean
  viewValue: TMainPanel
  link?: string
  name: string
  icon: any
}

export const additionalSections: IAdditionalSections[] = [
  {viewValue: 'eventsList', name: 'Мероприятия', icon: Icon28BookmarkOutline},
  {viewValue: 'spaces', name: "Бронирование пространств", icon: ReservationIcon},
  {viewValue: 'gospublics', name: 'Госпаблики', icon: GospublicsIcon},
  {viewValue: 'weRussians', name: 'Мы россияне', icon: WeRussiansIcon},
  {viewValue: 'spacesMap', name: 'Прострастнва', icon: MapIcon},
  {viewValue: 'main', link: 'https://vk.com/im?sel=-28219562', name: 'Обратная связь', icon: FeedbackIcon},
  {isModal: true, viewValue: 'gospublics', name: 'Туториал', icon: TutorialIcon},
]
